






















import { Component, Vue } from 'vue-property-decorator'
import AddCard from '../AddCard.vue'
import UserForm from './UserForm.vue'
import { User } from '@/model/User'
import { ROLES } from '@/model/Role'

@Component({
  components: { AddCard, UserForm },
})
export default class AddUser extends Vue {
  user: User = {}
  adding = false

  addUser() {
    console.log(this.user)
    this.$emit('add', { ...this.user, role: ROLES.ADMIN })
  }

  updateUser({ key, value }: { key: keyof User; value: any }) {
    this.user[key] = value
  }

  reset() {
    this.adding = false
    this.user = {}
  }
}
