



































































import { Component, Ref, Vue } from 'vue-property-decorator'
import AddUser from '@/components/core/user/AddUser.vue'
import CardUser from '@/components/core/user/CardUser.vue'
import { User } from '@/model/User'
import { ResetableComponent } from '@/model/ResetableComponent'
import { ROLES } from '@/model/Role'
import { Tab } from '../../components/base/BaseTab.vue'
import SlidingPagination from 'vue-sliding-pagination'

@Component({
  components: { AddUser, CardUser, SlidingPagination },
})
export default class ViewUsers extends Vue {
  @Ref('addUser') readonly addUserEl!: ResetableComponent

  usersPerPage = 10
  currentPageAdmin = 1
  currentPageUser = 1
  get totalPagesAdmin(): number {
    return this.adminUsers.length / this.usersPerPage + 1
  }
  get totalPagesUser(): number {
    return this.otherUsers.length / this.usersPerPage + 1
  }

  searchUser = ''
  searchAdmin = ''

  pageChangeAdmin(page: number) {
    if (page > 0) this.currentPageAdmin = page
  }

  pageChangeUser(page: number) {
    if (page > 0) this.currentPageUser = page
  }

  tabs: Tab[] = [
    { id: 1, label: this.$t('roles.user').toString() + 's' },
    { id: 2, label: this.$t('roles.admin').toString() + 's' },
  ]

  get canAddUser(): boolean {
    return (
      this.$store.getters.loggedUser.role === ROLES.ADMIN ||
      this.$store.getters.loggedUser.role === ROLES.SUPERADMIN
    )
  }

  addUser(user: User) {
    this.$store.dispatch('addUser', user).then(json => {
      this.$store.dispatch('toaster/toast', json)

      if (json.success) {
        this.addUserEl.reset()
      }
    })
  }

  created() {
    this.$store.dispatch('getUsers')
  }

  get users(): User[] {
    return this.$store.getters.users
  }

  get adminUsers(): User[] {
    return this.users.filter(u => {
      return (
        u.role === ROLES.ADMIN &&
        (!this.searchAdmin ||
          (u.firstname && u.firstname.match(this.searchAdmin)) ||
          (u.lastname && u.lastname.match(this.searchAdmin)) ||
          (u.username && u.username.match(this.searchAdmin)) ||
          (u.email && u.email.match(this.searchAdmin)))
      )
    })
  }

  get otherUsers(): User[] {
    return this.users.filter(u => {
      return (
        u.role === ROLES.USER &&
        (!this.searchUser ||
          (u.firstname && u.firstname.match(this.searchUser)) ||
          (u.lastname && u.lastname.match(this.searchUser)) ||
          (u.username && u.username.match(this.searchUser)) ||
          (u.email && u.email.match(this.searchUser)))
      )
    })
  }

  get adminUsersPaginate(): User[] {
    return this.adminUsers.slice(
      (this.currentPageAdmin - 1) * this.usersPerPage,
      this.currentPageAdmin * this.usersPerPage,
    )
  }

  get otherUsersPaginate(): User[] {
    return this.otherUsers.slice(
      (this.currentPageUser - 1) * this.usersPerPage,
      this.currentPageUser * this.usersPerPage,
    )
  }
}
