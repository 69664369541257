

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AddCard extends Vue {
  @Prop({ default: '' }) title!: string

  validate() {
    this.$emit('validate')
  }

  cancel() {
    this.$emit('cancel')
  }
}
