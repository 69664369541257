























import { Component, Prop, Vue } from 'vue-property-decorator'
import { User, USER_STATES } from '@/model/User'

@Component({})
export default class CardProject extends Vue {
  @Prop() user!: User

  get state(): string {
    return this.user.state === USER_STATES.ENABLED
      ? this.$t('enabled').toString()
      : this.$t('disabled').toString()
  }

  created() {
    this.$store
      .dispatch('getUserImage', { userId: this.user.id })
      .then(json => {
        this.src = json.data
      })
  }

  src = ''

  goToUser() {
    this.$router.push({ name: 'User', params: { id: '' + this.user.id } })
  }
}
